<template>
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <div class="framePage-title">
                <span>
                <em>当前位置：</em>
                <a href="javascript:;">培训管理</a>
                <i>></i>
                <a href="javascript:;" @click="$router.back()">练习题库配置</a>
                <i>></i>
                <a href="javascript:;" class="cur-a">查看名单</a>
                </span>
            </div>
            <div class="framePage-body">
                <div class="operationControl ">
                    <div class="searchbox">
                        <div title="姓名" class="searchboxItem ci-full">
                            <span class="itemLabel">姓名:</span>
                            <el-input size="small" v-model="searchForm.userName" clearable placeholder="请输入姓名"></el-input>
                        </div>
                        <div title="手机号" class="searchboxItem ci-full">
                            <span class="itemLabel">手机号:</span>
                            <el-input size="small" v-model="searchForm.mobile" clearable placeholder="请输入手机号"></el-input>
                        </div>
                        <div class="searchboxItem">
                            <el-button type="primary" class="bgc-bv" round @click="getListData(1)">查询</el-button>
                        </div>
                        <div class="searchboxItem">
                            <el-button type="primary" class="bgc-bv" round @click="exportData">导出</el-button>
                        </div>
                    </div>
                </div>
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <el-table
                            ref="multipleTable"
                            :data="listData"
                            :height="tableHeight"
                            size="small"
                            tooltip-effect="dark"
                            style="width: 100%"
                            :header-cell-style="tableHeader"
                            stripe
                        >
                            <el-table-column
                                label="序号"
                                type="index"
                                :index="indexMethod"
                                align="center"
                                width="50"
                            />
                            <el-table-column
                                label="姓名"
                                prop="userName"
                                show-overflow-tooltip
                            />
                            <el-table-column
                                label="手机号"
                                prop="mobile"
                                show-overflow-tooltip
                            />
                            <el-table-column
                                label="练习进度"
                                prop="progress"
                                show-overflow-tooltip
                            />
                            <el-table-column
                                label="练题数量"
                                prop="studyNum"
                                show-overflow-tooltip
                            />
                            <el-table-column
                                label="最后练习时间"
                                prop="lastStudyTime"
                                show-overflow-tooltip
                            />
                            <Empty slot="empty" />
                        </el-table>
                    </div>
                </div>
                <PageNum :apiData="pagination" @sizeChange="sizeChange" @getData="currentChange"/>
            </div>
        </div>
    </div>
</template>

<script>
    import List from "@/mixins/List.js";
    import PageNum from "@/components/PageNum.vue";
    import Empty from "@/components/Empty.vue";
    import {mapGetters} from "vuex";
    export default {
        name:"ViewList",
        mixins:[List],
        components:{
            PageNum,
            Empty
        },
        data(){
            return{
                // 查询表单
                searchForm:{
                    userName:"",//姓名
                    mobile:"",// 手机号
                    configId:"", // 配置id
                },
                // 分页器数据
                pagination:{
                    current:1,
                    size:10,
                    total:0
                },
                // 列表数据
                listData:[]
            }
        },
        computed:{
            ...mapGetters({
                downloadItems: "getDownloadItems",
            }),
        },
        methods:{
          // 序号
          indexMethod(index) {
            return (this.pagination.current - 1) * this.pagination.size + index + 1;
          },
            // 初始化树
            initData(){
                this.searchForm.configId=this.$route.query.configId||"";
                this.getListData();
            },
            // 获取列表数据
            getListData(current){
                const {pagination,searchForm}=this;
                if(current){
                    pagination.current=current;
                }
                this.$post("/biz/ct/questionBankConfig/selectUserByConfigId",{
                    ...searchForm,
                    pageNum:pagination.current,
                    pageSize:pagination.size
                }).then(res=>{
                    this.listData=res?.data?.list||[];
                    pagination.total=res?.data?.total;
                }).catch(e=>{
                    console.error("获取列表数据出错",e);
                });
            },
            // 导出
            exportData(){
                const {searchForm}=this;
                this.$post("/biz/ct/questionBankConfig/exportUserByConfigId",searchForm).then(res=>{
                    console.log(res);
                    const arr=[res.data];
                    for (let item of arr) {
                        if (!this.downloadItems.includes(item.taskId)) {
                            this.$store.dispatch("pushDownloadItems", item.taskId);
                        } else {
                            this.$message.warning(
                                "[" + item.fileName + "]已经申请下载,请耐心等待"
                            );
                        }
                    }
                }).catch(e=>{
                    console.error("导出学员出错",e);
                });
            },
            // 当前页改变
            currentChange(current){
                const {pagination}=this;
                pagination.current=current;
                this.getListData();
            },
            // 当前页条数改变
            sizeChange(size){
                const {pagination}=this;
                pagination.size=size;
                this.getListData();
            },
            // 获取表格高度
            getTableHeight() {
                this.tableHeight = window.innerHeight - 260;
            },
        },
        created(){
            this.initData();
        }
    }
</script>

<style scoped lang="less">

</style>